/* eslint-disable max-classes-per-file */
import { QuestionEvaluationModel } from 'core/models/elements/evaluations/questionEvaluation.abstract.model';
import { AreaModel } from '../components/area.model';

/**
 * @class Defines an evaluation of a hotspot question
 */
export class HotspotEvaluationModel extends QuestionEvaluationModel {
  /**
   * @constructor Creates an instance of a hotspot evaluation.
   * @param {hotspotQuestion} hotspotQuestion is the element to be evaluated.
   */
  constructor(hotspotQuestion) {
    super(hotspotQuestion);
    this.areas = hotspotQuestion.areas?.map((a) =>
      a ? new AreaModel(a) : null,
    );
    this.x = hotspotQuestion.x;
    this.y = hotspotQuestion.y;
  }
}

